<template>
  <div class="app-container">
    <eHeader  :query="query" :dimensionoptions="listDimensionOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <!-- <el-table-column prop="dimensionId" label="维度名称"/> -->
      <el-table-column prop="dimensionId" label="维度名称">
        <template slot-scope="scope">
          <span>{{ parseDimension(scope.row.dimensionId) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableName" label="表名"/>
      <el-table-column prop="columnName" label="字段名"/>
      <el-table-column prop="comment" label="备注"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status ? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SRPERMISSIONDIMENSIONTABLE_ALL','SRPERMISSIONDIMENSIONTABLE_EDIT'])" :data="scope.row" :sup_this="sup_this" :dimensionoptions="listDimensionOptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','SRPERMISSIONDIMENSIONTABLE_ALL','SRPERMISSIONDIMENSIONTABLE_DELETE'])"
            :ref="scope.row.productId"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
// import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/system/dataper/dptable/header'
import edit from '@/components/system/dataper/dptable/edit'
import { delDPTable, getDPDimension } from '@/api/system/dataper'

export default {
  name:'dptable',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      listDimensionOptions: []
    }
  },
  created() {
    this.queryDimensionOption()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'user/crm/srPermissionDimensionTable'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const dimensionId = query.dimensionId
      const tableName = query.tableName
      if (dimensionId !== '' && dimensionId !== null) { this.params['dimensionId'] = dimensionId }
      if (tableName !== '' && tableName !== null) { this.params['tableName'] = tableName }
      return true
    },
    queryDimensionOption(){
     const params = ''
     getDPDimension(params).then(res => {
       this.listDimensionOptions = res.content
      })
    },
    parseDimension(params){
      for (var i = this.listDimensionOptions.length - 1; i >= 0; i--) {
        if(this.listDimensionOptions[i].id==params){
          return this.listDimensionOptions[i].dimensionName
        }
      }
    },
    subDelete(id) {
      this.delLoading = true
      delDPDimension(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
